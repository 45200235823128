


















































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { api } from '@/api';

@Component
export default class Signup extends Vue {
  public email = '';
  public fullName = '';
  public occupation = '';
  public usageReason = '';
  public step = 1;
  public submitMsg = ''
  public loading = false;

  public next() {
    switch (this.step) {
      case 1: this.validateField('fullName');
        break;
      case 2: this.validateField('email');
        break;
      case 3: this.validateField('occupation');
        break;
      case 4: this.validateField('usageReason');
    }
  }

  get mobile() {
    return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm';
  }

  public validateField(field: string) {
    if (!this.$validator.errors.first(field) && this[field].length > 0) {
      this.step++;
    } else {
      this.$validator.validate(field);
    }
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.loading = true;
      try {
        const response = await api.signUp({
          full_name: this.fullName,
          email: this.email,
          occupation: this.occupation,
          usage_reason: this.usageReason,
        });
        if (response) {
          this.submitMsg = 'You have been added to the beta waiting list.';
        }
      } catch (error) {
        this.submitMsg = 'You\'re already signed up for the beta.';
      }
      this.step++;
    }
    this.loading=false;
  }

  beforeDestroy() { this.$validator.pause(); }
}
